"use client";

import Icon from "@atoms/icon/Icon";
import { cn, tw } from "@utils/tailwind";
import { cva } from "class-variance-authority";
import parse, { domToReact } from "html-react-parser";
import { FC, useMemo, useRef, useState } from "react";
import styles from "./CollapsableList.module.css";
import { CollapsableListProps } from "./CollapsableList.types";
import type { DOMNode } from "html-dom-parser";

const baseCircleContainerStyles = tw`relative
  ml-auto
  mt-1
  flex
  size-6
  items-center
  justify-center
  rounded-[50%]
  border
  border-solid
  border-content-primary-on-light
  transition-colors
  duration-300
  ease-in-out
  group-hover:bg-content-primary-on-light
  group-focus:border-green-accent-one
  group-focus:bg-transparent
  group-active:bg-green-dark
  md:mt-0
  md:size-9`;

const basePlusIconStyles = tw`size-2.5
  transition-all
  duration-300
  ease-in-out
  group-hover:fill-green-accent-one
  group-focus:fill-content-primary-on-light
  group-active:fill-green-accent-one
  md:size-3.5`;

export const CollapsableList: FC<CollapsableListProps> = ({
  items,
  isNumeric = false,
  variant = "page",
  className,
}) => {
  const [expandedId, setExpandedId] = useState<string>();
  const contentsRef = useRef<Map<string, HTMLDivElement>>(new Map());

  const collapsableElementStyles = cva(
    "border-t border-solid bg-transparent pt-5 xl:pt-9",
    {
      variants: {
        variant: {
          page: "border-content-primary-on-light text-content-primary-on-light md:pt-7 lg:pt-8",
          blog: "border-[#bcbcbc] text-content-primary-on-light sm:pt-6",
        },
      },
    }
  );

  const headingStyles = cva("max-w-[80%] font-medium", {
    variants: {
      variant: {
        page: "text-lg tracking-[-0.01em] md:text-xl xl:text-2xl xl:tracking-normal",
        blog: "font-termina text-base xl:text-lg",
      },
    },
  });

  const contentContainerStyles = cva(
    "links-container mb-5 overflow-hidden transition-all duration-300 ease-in-out xl:mb-9 [&_p]:pr-[30px]",
    {
      variants: {
        variant: {
          page: "md:mb-7 lg:mb-8",
          blog: "sm:mb-6",
        },
      },
    }
  );

  const containerInnerStyles = cva(
    "pt-4 text-sm text-content-primary-on-light/90 md:pt-5 md:text-base md:leading-[1.625rem]",
    {
      variants: {
        variant: {
          page: "xl:text-lg",
          blog: "pr-12 leading-6 md:pr-20 xl:pr-24 xl:pt-6 2xl:pt-7",
        },
      },
    }
  );

  const itemsWithIcons = useMemo(
    () =>
      items
        ? items.map((item, ind) => ({
            ...item,
            id: `${item.title}-${ind}`,
            description: item.description
              ? parse(item.description, {
                  replace(node) {
                    if (node.type === "tag" && node.name == "li") {
                      return (
                        <li className="mb-2.5 flex list-none last:mb-0 lg:mb-5 lg:ml-[5px] lg:last:mb-0">
                          <div
                            data-name="Left icon container"
                            className="mr-[15px] pt-2"
                          >
                            <Icon
                              name="Thick"
                              className="h-[7.5px] min-w-[10px] fill-green-accent-two sm:h-2.5 sm:min-w-[13px]"
                            />
                          </div>
                          <span>{domToReact(node.children as DOMNode[])}</span>
                        </li>
                      );
                    }
                  },
                })
              : "",
          }))
        : null,
    [items]
  );

  const onItemClicked = (id: string) => {
    setExpandedId((prev) => (prev === id ? undefined : id));
  };

  const getMaxHeight = (id: string): number =>
    expandedId === id && contentsRef.current.has(id)
      ? (contentsRef.current.get(id)?.scrollHeight ?? 0)
      : 0;

  const getRefHandler = (id: string) => (node: HTMLDivElement | null) => {
    const map = contentsRef.current;
    if (node) map.set(id, node);
    else map.delete(id);
  };

  return (
    <div className={cn(className)}>
      {itemsWithIcons &&
        itemsWithIcons.map((item, index) => {
          const isExpanded = expandedId === item.id;
          return (
            <div
              data-name="Collapsible List"
              key={item.id}
              onClick={() => onItemClicked(item.id)}
              className="group cursor-pointer"
            >
              <div
                data-name="Element"
                className={collapsableElementStyles({ variant })}
                key={item.title}
              >
                <div
                  data-name="Title container"
                  className={cn(
                    "flex",
                    !isNumeric && "md:items-center",
                    isNumeric && "lg:items-center"
                  )}
                >
                  <div className={headingStyles({ variant })}>
                    {isNumeric && `${index + 1}. `}
                    {item.title}
                  </div>
                  <div
                    className={cn(
                      baseCircleContainerStyles,
                      isExpanded
                        ? "bg-content-primary-on-light"
                        : "bg-content-primary-on-dark"
                    )}
                  >
                    <Icon
                      name="Plus"
                      data-name="Plus"
                      className={cn(
                        basePlusIconStyles,
                        isExpanded
                          ? "rotate-45 fill-content-primary-on-dark"
                          : "fill-content-primary-on-light",
                        isExpanded &&
                          variant === "page" &&
                          "2xl:fill-green-accent-one"
                      )}
                    />
                  </div>
                </div>
              </div>
              <div
                data-name="Content container"
                className={contentContainerStyles({
                  variant,
                  className: styles.lists,
                })}
                ref={getRefHandler(item.id)}
                style={{ maxHeight: getMaxHeight(item.id) }}
              >
                <div
                  data-name="Content container inner"
                  className={containerInnerStyles({ variant })}
                >
                  {item.description}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
